<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'PayList',
  }
</script>

<style></style>
